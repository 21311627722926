* {
  padding:0;
  margin:0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.activeDate {
  color: #fff;
  font-size: 12px;
  letter-spacing: 0px;
  font-family: Roboto;
  font-weight: 600;
  cursor: pointer;
  margin: 2px 0;
  text-align: center;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #12B2D6;
}

select {
  outline: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.animation_progress_bar_start {
  animation-name: animation_progress_bar_start;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

.animation_progress_bar_end {
  animation-name: animation_progress_bar_end;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

/* Home classes */

.App-footer {
  background-color: #282c34;
  /* min-height: 100vh; */
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.MuiInputBase-input {
  color: #00000099 !important;
}

button:focus { outline: none; border: none; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] { -moz-appearance:textfield; }

a[download] {
  text-decoration: none;
  font-family: "Helvetica Neue", sans-serif;
}

/**ANIMATION**/

@keyframes ldio-jboo32xyurc {
  0% { transform: translate(6px,40px) scale(0); }
  25% { transform: translate(6px,40px) scale(0); }
  50% { transform: translate(6px,40px) scale(1); }
  75% { transform: translate(40px,40px) scale(1); }
  100% { transform: translate(74px,40px) scale(1); }
}
@keyframes ldio-jboo32xyurc-r {
  0% { transform: translate(74px,40px) scale(1); }
  100% { transform: translate(74px,40px) scale(0); }
}
@keyframes ldio-jboo32xyurc-c {
  0% { background: #12b2d6 }
  25% { background: #12b2d6 }
  50% { background: #12b2d6 }
  75% { background: #12b2d6 }
  100% { background: #12b2d6 }
}

@keyframes animation_progress_bar_start {
  from {
    width: 0%;
  }
  to {
    width: 25%;
  }
}


@keyframes animation_progress_bar_end {
  from {
    width: 25%;
  }
  to {
    width: 100%;

  }
}

.ldio-jboo32xyurc div {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translate(40px,40px) scale(1);
  background: #12b2d6;
  animation: ldio-jboo32xyurc 1s infinite cubic-bezier(0,0.5,0.5,1);
}
.ldio-jboo32xyurc div:nth-child(1) {
  background: #12b2d6;
  transform: translate(74px,40px) scale(1);
  animation: ldio-jboo32xyurc-r 0.25s infinite cubic-bezier(0,0.5,0.5,1), ldio-jboo32xyurc-c 1s infinite step-start;
}.ldio-jboo32xyurc div:nth-child(2) {
   animation-delay: -0.25s;
   background: #12b2d6;
 }.ldio-jboo32xyurc div:nth-child(3) {
    animation-delay: -0.5s;
    background: #12b2d6;
  }.ldio-jboo32xyurc div:nth-child(4) {
     animation-delay: -0.75s;
     background: #12b2d6;
   }.ldio-jboo32xyurc div:nth-child(5) {
      animation-delay: -1s;
      background: #12b2d6;
    }
.loadingio-spinner-ellipsis-x4scbjvj2d {
  width: 40px;
  height: 40px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-jboo32xyurc {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.4);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.ldio-jboo32xyurc div { box-sizing: content-box; }

input[name="option_filters"]:checked + i[data-filter="filter"] {
  background-color: #0D843C !important;
  position: relative;
}
input[name="option_filters"]:checked + i[data-filter="filter"]:before {
  content: '✔';
  font-size: 14.5px;
  height: 5px;
  color: #fff;
  position: absolute;
  top: -1px;
  left: 1px;
}

input {
  outline: none;
}


@keyframes animation_circle_white {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1.5,1.5);
  } 100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1,1);
  }
}
.animation_circle_white div > div {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ffffff;
  animation: animation_circle_white 1s linear infinite;
}.animation_circle_white div:nth-child(1) > div {
  left: 74px;
  top: 44px;
  animation-delay: -0.875s;
}
.animation_circle_white > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 80px 50px;
}.animation_circle_white div:nth-child(2) > div {
  left: 65px;
  top: 65px;
  animation-delay: -0.75s;
}
.animation_circle_white > div:nth-child(2) {
  transform: rotate(45deg);
  transform-origin: 71px 71px;
}.animation_circle_white div:nth-child(3) > div {
  left: 44px;
  top: 74px;
  animation-delay: -0.625s;
}
.animation_circle_white > div:nth-child(3) {
  transform: rotate(90deg);
  transform-origin: 50px 80px;
}.animation_circle_white div:nth-child(4) > div {
  left: 23px;
  top: 65px;
  animation-delay: -0.5s;
}
.animation_circle_white > div:nth-child(4) {
  transform: rotate(135deg);
  transform-origin: 29px 71px;
}.animation_circle_white div:nth-child(5) > div {
  left: 14px;
  top: 44px;
  animation-delay: -0.375s;
}
.animation_circle_white > div:nth-child(5) {
  transform: rotate(180deg);
  transform-origin: 20px 50px;
}.animation_circle_white div:nth-child(6) > div {
  left: 23px;
  top: 23px;
  animation-delay: -0.25s;
}
.animation_circle_white > div:nth-child(6) {
  transform: rotate(225deg);
  transform-origin: 29px 29px;
}.animation_circle_white div:nth-child(7) > div {
  left: 44px;
  top: 14px;
  animation-delay: -0.125s;
}
.animation_circle_white > div:nth-child(7) {
  transform: rotate(270deg);
  transform-origin: 50px 20px;
}.animation_circle_white div:nth-child(8) > div {
  left: 65px;
  top: 23px;
  animation-delay: 0s;
}
.animation_circle_white > div:nth-child(8) {
  transform: rotate(315deg);
  transform-origin: 71px 29px;
}
.container_animation_circle_white {
  width: 24px;
  height: 24px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.animation_circle_white {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.24);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.onArrowAnimate {
  transform: rotateZ(180deg) !important;
}

[data-tooltip] {
  position: relative;
  z-index: 800;
  cursor: help;
}
[data-tooltip]:hover {
  overflow: initial;
}

[data-tooltip]:after {
  position: absolute;
  top: calc(50% - 14px);
  left: 85%;
  width: 0;
  border-bottom: 10px solid black;
  border-right: 10px solid black;
  border-left: 10px solid transparent;
  border-radius: 0 0 0 4px;
  border-top: 10px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
  display: none;
}

[data-tooltip]:before {
  position: absolute;
  content: attr(data-tooltip);
  color: #fff;
  line-height: 15px;
  padding: 3px 7px 6px;
  background-color: black;
  width: max-content;
  max-width: 186px;
  border-radius: 6px;
  max-height: 67px;
  overflow: hidden;
  font-weight: 400;
  text-align: justify;
  z-index: 50;
  bottom: calc(50% - 6px);
  white-space: pre-wrap;
  left: calc(85% + 15px);
  font-size: 12px;
  display: none;
}

[data-tooltip]:hover:before {
  display: flex;
}
[data-tooltip]:hover:after {
  display: flex;
}


.animation_circle_white div { box-sizing: content-box; }

.arrowAnimate {
  transition: all 1s;
  transform: rotateZ(0deg);
  margin-left: 10px;
}


[data-tooltip-left] {
  position: relative;
  cursor: help;
}
[data-tooltip-left]:hover {
  overflow: initial;
}


[data-tooltip-left]:before {
  position: absolute;
  content: attr(data-tooltip-left);
  color: #fff;
  line-height: 15px;
  padding: 3px 7px 6px;
  background-color: black;
  width: max-content;
  max-width: 186px;
  border-radius: 6px;
  max-height: 67px;
  overflow: hidden;
  font-weight: 400;
  text-align: justify;
  z-index: 50;
  bottom: calc(50% - 6px);
  white-space: pre-wrap;
  right: calc(69% + 15px);
  font-size: 12px;
  display: none;
}

[data-tooltip-left]:hover:before {
  display: flex;
}
[data-tooltip-left]:hover:after {
  display: flex;
}